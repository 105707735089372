:root {
  --tab-background-color: #bbd4be;
  --tab-border-color: #aad4af;
  --tab-hover-color: #c4ddc6;
}
html * {
  /* 
    Prevent iOS chrome fonts from blowing up when users have font accessibility
    set to larger than normal.  Unfortunately these features are massively
    inconsistent across browsers, so consistency between browsers was better
    than nothing. TODO: try to enable font-size accessibility in *all* browsers
    on iOS.
  */
  -webkit-text-size-adjust: none;
}

/* Show a warning to rotate back for small devices in landscape orientation */
.landscape-warning {
  display: none;
}
@media screen and (max-height: 550px) and (orientation: landscape) {
  .landscape-warning {
    line-height: 1.1;
    padding: 0.75em;
    background-color: #800;
    color: #fff;
    font-size: 1.6em;
    text-align: center;
    display: unset;
    z-index: 100;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  line-height: 1rem;
}
svg {
  fill: currentColor;
  stroke: currentColor;
}

form .btn-secondary {
  color: #fff;
  background-color: #bbb;
  border-color: #bbb;
}
form .btn-secondary:hover {
  background-color: #a8aeb3;
  border-color: #9da4aa;
}

.row {
  margin: 0;
}
.col {
  padding: 0;
}

.form-control:focus {
  border-color: #2684ff;
  box-shadow: 0 0 0 1px #2684ff;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-color: var(--tab-border-color);
  border-bottom-color: var(--tab-border-color);
}
.nav-tabs .nav-link {
  border-bottom-color: var(--tab-background-color);
  height: 5.625rem;
  padding-right: 1.75rem;
  padding-left: 1.75rem;
}
.nav-tabs .nav-link.active {
  color: #000;
  background-color: var(--tab-background-color);
  border-bottom-color: var(--tab-background-color);
}
.topbar .nav-tabs {
  flex-grow: 1;
}
.topbar .navbar-brand img {
  height: 75px;
  width: 288.369px;
}
.topbar .navbar-brand {
  padding: 13.5px 0px 13.5px 14px;
  margin-right: 0;
}

.topbar .login-container {
  display: inline-block;
  text-align: right;
  padding-right: 0.9375rem;
}
.topbar .nav-tabs .nav-item:first-child {
  margin-left: 0.9375rem;
}
.nav-tabs {
  border-bottom: 1px solid var(--tab-background-color);
  flex-wrap: nowrap;
  padding-top: 0.75rem;
  margin-bottom: -0.125rem;
}

.modal-header button.close {
  background-color: transparent;
  border: 0;
  border-radius: 50px;
  line-height: 50px;
  height: 50px;
  width: 50px;
  margin-right: 5px;
  font-size: 1.375rem;
  display: inline-block;
  padding: 0;
  transition: background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.modal-header button.close span {
  /* 
    Not sure why this is needed, but without it, the width of the parent button
    is constrained to a smaller width when the modal header has two lines of
    text.  Forcing a width of the child element seems to fix it. 
  */
  width: 50px;
  display: inline-block;
}
.modal-header button.close:hover {
  background-color: #9fc1a2;
}

.container-fluid {
  background-color: #fff;
  padding: 0;
  margin: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  /* Warning: setting the CSS below, which was originally added to work around
  Safari behavior, caused weird and unpredictable flexbox behavior, like
  flex-shrink:0 items shrinking to zero height. Removing it didn't seem to break
  anything so leaving it out. But be careful!
  Given that I don't know why it was broken in the first place, I'm a little
  worried about the problem coming back later, so in case it does, consider
  the `min-height: 0` trick (for the scroll container) that's recommended here: 
  https://moduscreate.com/blog/how-to-fix-overflow-issues-in-css-flex-layouts/
    overflow: hidden;
    height: 100vh;
  */
}

.topbar {
  display: flex;
  flex-wrap: nowrap;
  flex-shrink: 0;
  padding: 0;
  background-color: #fdf1c6;
  width: 100%;
  height: auto;
}

.main-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.tab-content {
  background-color: #fff;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.bottom-bar {
  display: none;
  background-color: #f5f5f5;
  width: 100%;
  text-align: right;
}

.bottom-bar .navbar-nav {
  display: flex;
  flex-direction: row;
  margin: 0;
}

.bottom-bar .navbar-nav .nav-link {
  color: #111;
  margin-left: 1.25rem;
}

@media (hover: hover) {
  .nav-tabs .nav-link:hover {
    border-color: var(--tab-hover-color);
  }
  .nav-tabs .nav-link.active:hover {
    border-bottom-color: var(--tab-background-color);
  }
}
.dropdown-toggle::after {
  vertical-align: 0.2em;
}
.dropdown-menu {
  padding: 0;
  margin: 0;
  background-color: #fff;
  border-color: #ddd;
  box-shadow: 0px 0px 10px -7px #ffffff;
}
.dropdown-item,
.dropdown-item a,
.dropdown-item a:hover {
  color: #555;
}

@media (hover: hover) {
  .dropdown-item:hover {
    background-color: #deebff;
  }
}
.dropdown-divider {
  margin: 0;
  border-color: #dfd8ce;
}
.btn-outline-secondary {
  background-color: #fff;
}

.calendar-container {
  display: flex;
  flex-wrap: nowrap;
  flex-flow: column;
  flex: 1;
  height: 100%;
}

/* Bottom (one-day) calendar detail */
.bottom-calendar-container {
  width: 100%;
  min-height: 12.5rem;
  height: auto;
  flex: 1 1 auto;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  scroll-snap-type: y mandatory; /* only used by list view, but OK in other views */
}

/* Top (two week) calendar */

.top-calendar,
.top-calendar-head,
.top-calendar-body,
.top-calendar-row {
  width: 100%;
}
.top-calendar:not(.top-calendar-multi) {
  background-color: #ccc;
}
.top-calendar-head {
  font-weight: bold;
  display: flex;
  flex-direction: row;
  background-color: var(--tab-background-color);
  vertical-align: middle;
  height: 1.875rem;
  line-height: 1.875rem;
}
.top-calendar:not(.top-calendar-multi) .top-calendar-head {
  border-bottom: 1px solid #fff;
}
.top-calendar-head li {
  flex: 1;
}

.top-calendar ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.top-calendar .top-calendar-row ul {
  border-width: 1px;
}
.top-calendar .big-date-day + .big-date-day {
  border-left: 1px solid #ddd;
}
.top-calendar.top-calendar-multi .big-date-container {
  margin-top: 0;
}
.top-calendar.top-calendar-multi .big-date-day {
  border-left: 1px solid #ddd;
}
.top-calendar.top-calendar-multi .big-date-day:last-child {
  border-right: 1px solid #ddd;
}
.top-calendar.top-calendar-multi .big-date-day.big-date-selected .big-date {
  background-color: #5bc567;
}
.top-calendar.top-calendar-multi .big-date-day.big-date-past .big-date,
.top-calendar.top-calendar-multi .big-date-day.big-date-past,
.top-calendar.top-calendar-multi .big-date-day.big-date-past:hover .big-date,
.top-calendar.top-calendar-multi .big-date-day.big-date-past:hover {
  color: #aaa;
  cursor: not-allowed;
}
@media (hover: hover) {
  .top-calendar.top-calendar-multi .big-date-day.big-date-past:hover .big-date {
    border-color: transparent;
  }
}

.top-calendar-row {
  height: 3.375rem;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ddd;
  align-items: center;
  align-content: center;
}

.top-calendar .big-date-day {
  cursor: pointer;
  padding: 0.125rem;
  flex: 1 1;
  align-self: stretch;
  display: flex;
  font-size: 1.75rem;
}
.top-calendar .big-date-day .big-date-text {
  font-size: 80%;
}
.modal-dialog form .top-calendar .big-date-day .big-date {
  font-size: 85%;
}
.top-calendar .big-date-day,
.top-calendar .top-calendar-head li {
  text-align: center;
}
.top-calendar .big-date-today .big-date {
  color: #a00;
}
.first-day-of-month .big-date-month-name {
  font-size: 65%;
  height: 0.99em;
  line-height: 1.75;
}
.first-day-of-month .big-date-text {
  font-size: 80%;
  line-height: 1.375;
}
.top-calendar.top-calendar-multi .big-date-container {
  margin-top: 0;
}

.top-calendar .big-date-container {
  /*  scroll-snap-type: y mandatory;*/
  scrollbar-width: none;
}

.top-calendar .big-date-container::-webkit-scrollbar {
  display: none;
}

.top-calendar .big-date {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  align-self: stretch;
  justify-content: center;
  height: 1.75em;
  width: 1.75em;
  max-height: 1.75em;
  max-width: 1.75em;
  border-radius: 1.75em;
  vertical-align: middle;
  line-height: 2;
  border: 2px solid transparent;
  transition: border-color 0.15s 0.05s, background-color 0.25s;
  margin: auto;
}
@media (hover: hover) {
  .top-calendar .big-date-day:hover .big-date {
    color: #212529;
    border-color: #565a5e;
  }
  .top-calendar.top-calendar-single .big-date-day:hover .big-date {
    background-color: #fff;
  }
  .top-calendar .big-date-day.big-date-today:hover .big-date {
    color: #a00;
    border-color: #565a5e;
  }
}

.top-calendar .big-date-day.big-date-selected .big-date {
  background-color: #fff;
  border-color: #41a74c;
}
.top-calendar .big-date-day.big-date-past .big-date {
  color: #aaa;
}

.middle-calendar {
  line-height: 3.5rem;
  text-align: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-shrink: 0;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #ccc;
  align-items: center;
  position: relative;
}
.middle-calendar.middle-calendar-list {
  position: relative;
  top: 0;
  width: auto;
  border-bottom: 0;
  display: inline-block;
  height: 0;
  text-align: right;
  z-index: 1;
  padding: 0;
}

.middle-calendar.middle-calendar.middle-calendar-list .middle-calendar-list-inner {
  width: auto;
  position: absolute;
  right: 0.3125rem;
  background-color: #fff;
  height: 3.5rem;
}
.calendar-date-range-medium,
.calendar-date-range-short {
  display: none;
}
.calendar-date-range {
  font-size: 1.75rem;
  margin: auto 0.625rem;
  display: inline-block;
  vertical-align: middle;
  white-space: nowrap;
}
.bottom-calendar-container .calendar-date-range {
  padding: 0 0.625rem;
  margin: 0;
  font-size: 1.75rem;
  line-height: 3.5rem;
  cursor: pointer;
  flex: 1 1;
}

/* react-big-calendar style overrides */
.bottom-calendar .rbc-time-header {
  display: none;
}
.bottom-calendar .rbc-time-content {
  border-top: 0;
}
.bottom-calendar .rbc-time-view {
  border: 0;
}
.bottom-calendar .rbc-time-slot {
  padding-top: 0.3125rem;
}
.bottom-calendar .rbc-label {
  font-size: 0.875rem;
  text-align: right;
  width: 100%;
  display: block;
}
.bottom-calendar .rbc-event {
  border-radius: 0;
}
.bottom-calendar .rbc-day-slot .rbc-event-content {
  line-height: 1.12;
}
.bottom-calendar .rbc-event.hang {
  background-color: #5bc567;
}

/* Styles for animation */

.notifier-height {
  transition: height 0.2s ease-in-out;
}

/* styles for social login  */

.login-dialog > .modal-content {
  background-color: #fdf1c6;
  align-items: center;
}

.facebook-login-button {
  border-color: #1877f2;
}
.facebook-login-button:hover {
  border-color: #185db7;
}

.google-login-button {
  border-color: #999;
}
.google-login-button:hover {
  border-color: #666;
}
.social-login-button:hover {
  background: #fff;
  color: #666;
}
.social-login-button {
  display: inline-flex;
  vertical-align: middle;
  margin: 0;
  cursor: pointer;
  color: #666;
  font-weight: bold;
  text-align: center;
  user-select: none;
  font-size: 14px;
  border-radius: 3px;
  white-space: nowrap;
  transition: all 0.4s;
  transition-property: background-color, background-position, background-size, border-color;
  background: #fff;
  background-position: 50% 50%;
  background-size: 15000px 15000px;
  background-image: none;
  background-repeat: no-repeat;
  width: 100%;
  max-width: 13.75rem;
  white-space: nowrap;
  padding: 0.5rem 1rem;
  flex: 1 0;
}

@media (hover: hover) {
  .social-login-button {
    background: #fbfaf4;
  }
}

.social-login-button svg {
  margin-right: 0.75rem;
}

.google-login-button svg {
  margin-right: calc(0.75rem + 2px);
}

/* screen-size-specific style changes */

@media only screen and (max-width: 991.98px) {
  .user-profile-name {
    display: none;
  }
  .topbar .login-container {
    width: 6rem; /* used to equalize tab layout between logged-in and non-logged-in states */
  }
}

@media only screen and (max-width: 639.98px) {
  .calendar-date-range-long,
  .calendar-date-range-medium,
  .calendar-date-range-short {
    display: none;
  }
  .calendar-date-range-medium {
    display: inline;
  }
  .navbar-brand {
    width: 83px;
    overflow: hidden;
    display: inline-block;
  }
}

@media only screen and (max-width: 499.98px) {
  /* hide the down-arrow dropdown icon next to the profile picture */
  .dropdown-toggle::after {
    display: none;
  }
  .top-calendar .big-date-day {
    font-size: 1.75rem;
  }
}

@media only screen and (max-width: 429.98px) {
  .calendar-date-range-long,
  .calendar-date-range-medium,
  .calendar-date-range-short {
    display: none;
  }
  .calendar-date-range-medium {
    display: inline;
  }
  .calendar-date-range {
    font-size: 1.5rem;
  }
  .topbar .nav-tabs .nav-link svg {
    height: 2.5rem;
    width: 3rem;
  }
  .topbar .nav-tabs {
    padding-top: 0.4375rem;
  }
  .topbar .navbar-brand img {
    height: 57px;
    width: 219.17px;
  }
  .topbar .navbar-brand {
    width: 63px;
    overflow: hidden;
    display: inline-block;
    padding: 0 0 0 0.625rem;
    margin-right: 0.3125rem;
  }
  .topbar .nav-tabs .nav-item:first-child {
    margin-left: 0.3125rem;
  }
  .topbar .nav-tabs .nav-item .nav-link {
    padding: 0.3rem 1.5rem;
    height: 4.375rem;
  }
  .topbar .dropdown.nav-item .nav-link {
    padding: 0.625rem 0.25rem 0.625rem 0;
  }
  .topbar .login-container {
    padding-right: 0.625rem;
    width: 4.875rem; /* used to equalize tab layout between logged-in and non-logged-in states */
  }
  .top-calendar .big-date-day {
    font-size: 1.625rem;
  }
}

@media only screen and (max-width: 369.98px) {
  .topbar .nav-tabs .nav-item .nav-link {
    padding: 0.3rem 1.25rem;
    height: 4.375rem;
  }
}

@media only screen and (max-width: 358.98px) {
  .calendar-date-range-long,
  .calendar-date-range-medium,
  .calendar-date-range-short {
    display: none;
  }
  .calendar-date-range-short {
    display: inline;
  }
  .top-calendar .big-date-day {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 332.98px) {
  .topbar .navbar-brand img {
    height: 35px;
    width: 134.58px;
  }
  .topbar .navbar-brand {
    width: 43px;
    margin-right: -4px;
  }
  .top-calendar .big-date-day {
    font-weight: bold;
    font-size: 1.25rem;
  }
}
